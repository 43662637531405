<template>
  <div class="footer-container">
    <p>SPARCS Server Landing Page 2023, Developer Triangle</p>
  </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>
.footer-container{
    text-align: center;
    vertical-align: bottom;
}
.footer-container > p{
    padding: 80px 20px 20px 20px;
    color: lightgrey;
}
</style>